const PartnerFooterDesktop = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1440"
    height="40"
    viewBox="0 0 1440 40"
    fill="none"
  >
    <path
      d="M0.0800781 0H1440.08C1440.08 22.0914 1422.17 40 1400.08 40H40.0801C17.9887 40 0.0800781 22.0914 0.0800781 0Z"
      fill="#010044"
    />
  </svg>
);

export default PartnerFooterDesktop;
