const PartnerMobileFooter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="393"
    height="20"
    viewBox="0 0 393 20"
    fill="none"
  >
    <path
      d="M0 0H393C393 11.0457 384.046 20 373 20H20C8.9543 20 0 11.0457 0 0Z"
      fill="#010044"
    />
  </svg>
);

export default PartnerMobileFooter;
